<template>
  <div class="service-category-setting">
    <PageTitle title="折扣碼設定" btn="新增" @btnClick="openDialog('create')" />

    <FiltersContainer>
      <BaseElInput
        v-model="nameSearch"
        clearable
        placeholder="輸入折扣碼名稱"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>
      <BaseElSelect
        v-model="isEnable"
        placeholder="是否啟用"
        value-key="value"
        clearable
        @clear="refresh(true)"
        @change="refresh(true)"
      >
        <BaseElSelectOption
          v-for="type in [
            { value: true, label: '啟用' },
            { value: false, label: '未啟用' },
          ]"
          :key="type.value"
          :label="type.label"
          :value="type"
        />
      </BaseElSelect>
    </FiltersContainer>

    <section>
      <BaseTable
        v-loading="loading"
        :data="productCategoryList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />

        <BaseElTableColumn prop="name" label="名稱" align="center" />
        <BaseElTableColumn prop="enable" label="啟用" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.enable)">
              {{
                scope.row.enable ? '是' : '否'
              }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="code" label="代碼" align="center" />
        <BaseElTableColumn
          prop="memberUseLimit"
          label="會員使用次數限制"
          align="center"
        />
        <BaseElTableColumn
          prop="shopUseLimit"
          label="全店使用次數限制"
          align="center"
        />
        <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="openEditDialog('update', (selectRow = scope.row))"
              @delete=";(deleteDialog = true), (selectRow = scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="productCategoryCount"
        @pageChange="refresh(false)"
      />
    </section>

    <!-- Dialog -->

    <PromoSettingDialog
      v-if="showDialog"
      :model.sync="formData"
      :type="dialogType"
      :dialogConfirm="dialogConfirm"
      @confirm="dialogConfirm"
      @close="showDialog = false"
    />

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deletePromoCode(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import PromoSettingDialog from '@/views/Ecommerce/Dialog/PromoSettingDialog.vue'
import { mapGetters } from 'vuex'
import EmptyBlock from '@/components/EmptyBlock.vue'

import {
  GetPromoCode,
  CreatePromoCode,
  FindPromoCode,
  GetPromoCodeCount,
  UpdatePromoCode,
  DeletePromoCode,
} from '@/api/ecommerce/promoCode'

import DeleteDialog from '@/components/Dialog/DeleteDialog'
import { promoReqConfig, promoContentConfig } from '@/config/ecommerce'
// Utils
import { pageStartIndex } from '@/utils/table'

export default {
  name: 'PromoCodeSetting',
  components: { DeleteDialog, EmptyBlock, PromoSettingDialog },
  computed: {
    ...mapGetters(['shop']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    requirement () {
      const form = this.formData
      return [
        {
          name: form.promoRequirements.name,
          type: form.promoRequirements.value,
          value: form.req,
        },
      ]
    },

    content () {
      const form = this.formData
      return [
        {
          name: form.promoContents.name,
          type: form.promoContents.value,
          value:
            form.promoContents.type === 'price'
              ? parseFloat(Number(form.contentPrice).toFixed(2))
              : parseFloat(Number(form.contentPercent).toFixed(2)),
        },
      ]
    },
  },
  data: () => ({
    loading: false,
    nameSearch: '',
    isEnable: '',
    uploadDialog: false,
    showDialog: false,
    deleteDialog: false,
    dialogType: '',
    promoReq: promoReqConfig,
    promoContent: promoContentConfig,

    productCategoryList: [],
    productCategoryCount: 0,

    selectRow: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    avatarChanged: false,

    formData: {
      isPublic: true,
      name: '',
      order: 100,
      id: '',
      enable: true,
      code: '',
      memberUseLimit: 100,
      shopUseLimit: 100,
      disableProductActivity: false,
      disableStorewideActivity: false,
      requirementLogic: 'OR',
      promoContents: '',
      promoRequirements: '',
      req: '',
      contentPercent: '',
      contentPrice: '',
      productBind: '',
      activityBind: '',
      promoRange: '',
    },
  }),

  async mounted () {
    await this.refresh()
  },

  methods: {
    promoReqDict (req) {
      return promoReqConfig[req]
    },
    promoContentDict (content) {
      return promoContentConfig[content]
    },
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      // if (!val) type = 'danger'
      return type
    },

    async refresh (search = false) {
      if (search) this.tableOptions.page = 1
      this.loading = true
      await this.getPromoCode()
      await this.getPromoCodeCount()
      this.loading = false
    },

    syncFormData (res) {
      const data = JSON.parse(JSON.stringify(res))
      this.formData.name = data.name
      this.formData.code = data.code
      this.formData.enable = data.enable
      const req = data.EcommercePromoRequirements[0]
      const content = data.EcommercePromoContents[0]
      this.formData.disableProductActivity = data.disableProductActivity
      this.formData.memberUseLimit = data.memberUseLimit
      this.formData.shopUseLimit = data.shopUseLimit
      this.formData.disableStorewideActivity = data.disableStorewideActivity
      this.formData.promoRequirements = this.promoReqDict(req.type)
      this.formData.promoContents = this.promoContentDict(content.type)
      this.formData.req = req.value
      const contentType = this.promoContentDict(content.type).type
      if (contentType === 'percent') { this.formData.contentPercent = content.value } else if (contentType === 'price') { this.formData.contentPrice = content.value }

      this.formData.promoRange = data.productRangeType
      this.formData.activityBind = data.ProductRangeByActivities[0]
      this.formData.productBind = data.ProductRangeByProducts[0]
    },

    async dialogConfirm () {
      // if (!(await this.checkForm())) return
      const type = this.dialogType
      if (type === 'create') this.createPromoCode()
      if (type === 'update') this.updatePromoCode()
      this.showDialog = false
    },

    //= > 取得產品類別
    async getPromoCode () {
      this.loading = true
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      const [res, error] = await GetPromoCode({
        shopId: this.shop,
        start: startIndex,
        limit,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
        enable: this.isEnable === '' ? undefined : this.isEnable.value,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryList = res
    },

    //= > 創建產品類別
    async createPromoCode () {
      this.loading = true
      const form = this.formData
      const [, error] = await CreatePromoCode({
        shopId: this.shop,
        name: form.name,
        enable: form.enable,
        code: form.code,
        memberUseLimit: form.memberUseLimit,
        shopUseLimit: form.shopUseLimit,
        disableProductActivity: form.disableProductActivity,
        disableStorewideActivity: form.disableStorewideActivity,
        requirementLogic: form.requirementLogic,
        promoContents: this.content,
        promoRequirements: this.requirement,
        productRangeType: form.promoRange,
        productRangeByActivities: form.activityBind
          ? [form.activityBind.id]
          : undefined,
        productRangeByProducts: form.productBind
          ? [form.productBind.id]
          : undefined,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      await this.refresh()
      this.$message.success('新增成功')
      this.resetFormData()
    },

    async findPromoCode () {
      const [res, error] = await FindPromoCode({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (error) return this.$message.error(error)
      this.syncFormData(res)
    },

    //= > 刪除產品類別
    async deletePromoCode () {
      this.loading = true
      const [, error] = await DeletePromoCode({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      await this.refresh()
      this.$message.success('刪除成功')
    },
    //= > 更新產品類別
    async updatePromoCode () {
      this.loading = true
      const form = this.formData
      const [, error] = await UpdatePromoCode({
        shopId: this.selectRow.ShopId,
        id: this.selectRow.id,
        name: form.name,
        enable: form.enable,
        code: form.code,
        memberUseLimit: form.memberUseLimit,
        shopUseLimit: form.shopUseLimit,
        disableProductActivity: form.disableProductActivity,
        disableStorewideActivity: form.disableStorewideActivity,
        requirementLogic: form.requirementLogic,
        promoContents: this.content,
        promoRequirements: this.requirement,
        productRangeType: form.promoRange,
        productRangeByActivities: form.activityBind
          ? [form.activityBind.id]
          : undefined,
        productRangeByProducts: form.productBind
          ? [form.productBind.id]
          : undefined,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      await this.refresh()
      this.$message.success('更新成功')
      this.resetFormData()
    },

    //= > 取得服務人員總數
    async getPromoCodeCount () {
      this.loading = true
      const [res, error] = await GetPromoCodeCount({
        shopId: this.shop,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryCount = res.count
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    async openEditDialog (type, row) {
      this.selectRow = row
      await this.findPromoCode()
      this.openDialog(type)
    },

    resetFormData () {
      this.formData = {
        isPublic: true,
        name: '',
        order: 100,
        id: '',
        enable: true,
        code: '',
        memberUseLimit: 100,
        shopUseLimit: 100,
        disableProductActivity: false,
        disableStorewideActivity: false,
        requirementLogic: 'OR',
        promoContents: '',
        promoRequirements: '',
        req: '',
        contentPercent: '',
        contentPrice: '',
        productBind: '',
        activityBind: '',
        promoRange: '',
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
